// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cocktails-js": () => import("./../../../src/pages/cocktails.js" /* webpackChunkName: "component---src-pages-cocktails-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-légales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-services-activation-de-marque-js": () => import("./../../../src/pages/services/activation-de-marque.js" /* webpackChunkName: "component---src-pages-services-activation-de-marque-js" */),
  "component---src-pages-services-atelier-cocktails-js": () => import("./../../../src/pages/services/atelier-cocktails.js" /* webpackChunkName: "component---src-pages-services-atelier-cocktails-js" */),
  "component---src-pages-services-bar-a-cocktails-evenementiel-js": () => import("./../../../src/pages/services/bar-à-cocktails-événementiel.js" /* webpackChunkName: "component---src-pages-services-bar-a-cocktails-evenementiel-js" */),
  "component---src-pages-services-bar-a-cocktails-mariage-js": () => import("./../../../src/pages/services/bar-à-cocktails-mariage.js" /* webpackChunkName: "component---src-pages-services-bar-a-cocktails-mariage-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

